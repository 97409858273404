import gql from "graphql-tag";

export const GET_TABLE_DEFINITION = gql`
query GetTableDefinition ($schema: String!, $name: String!) {
    LDPIngestQueryGroup {
      TableDefinition(TableSchema: $schema, TableName: $name) {
        ColumnName
        DataType
        IsNullable
        MaxLength
        Precision
        PrimaryKey
        Scale
      }
    }
  }
`;