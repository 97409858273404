import gql from "graphql-tag";

export const GET_CONFIG_DATA_CHANGES = gql`
query GetConfigDataChanges($tableName: String, $tableId: ID) {
    LDPConfigQueryGroup {
      GetConfigDataChange(TableName: $tableName, TableId: $tableId)
    }
  }
`;

export const GET_LEAD_DATA_LOOKUP_CONFIG = gql`
query LeadDataLookupConfig ($where: String) {
    LDPConfigQueryGroup {
      LeadDataLookupConfig (limit:9999, where: $where) {
        ColumnName
        CreatedDate
        LeadDataLookupConfigId
        ModifiedDate
        SubVerticalId
        VerticalId
      }
    }
  }
`;

export const GET_LEAD_DATA_LOOKUP = gql`
query GetLeadDataLookup ($columnName: String, $verticalId: ID, $subVerticalId: ID) {
    LDPConfigQueryGroup {
      GetLeadDataLookup (ColumnName: $columnName, VerticalId: $verticalId, SubVerticalId: $subVerticalId)
    }
  }
`;
