import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import {
  Grid, Theme,
  TextField, Button,
  makeStyles,
  createStyles,
} from "@material-ui/core";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useModal } from "./modal";

interface DataMap {
  [datakey: string]: string;
}

interface ParamPair {
  name: string;
  value: string;
}

interface TransformMapperEditorProps {
  onChange?: (updatedValues: DataMap) => void;
  options: string | DataMap;
  keyValuePairs: string | DataMap;
  fieldNameDataType: string;
}

const filter = createFilterOptions();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: "20vh",
      overflowY: "auto",
      "& .MuiTextField-root": {
        width: "100%",
      },
      marginTop: "10px"
    },
    rootInput: {
      overflowY: "auto",
      "& .MuiTextField-root": {
        width: "100%",
      },
      minHeight: "80px"
    },
    buttonGrid: {
      marginTop: '5px',
      alignItems: 'center'
    }
  })
);

const transformMapperEditor = ({ onChange, options, keyValuePairs, fieldNameDataType }: TransformMapperEditorProps, ref: any) => {
  const [paramList, setParamList] = useState<Array<ParamPair>>([]);
  const [optionsList, setOptionsList] = useState([]);
  const [inputParameter, setInputParameter] = useState<ParamPair>({
    name: '', value: ''
  });

  const { register, handleSubmit, watch, setValue, errors } = useForm<DataMap>();
  const [keyFocus, setKeyFocus] = useState<number | null>(null);

  const classes = useStyles();
  const { Modal, closeModal, openModal } = useModal(undefined, 0.35);

  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    setValue: (val: string) => {
      if (keyFocus !== null) {
        changeValue(keyFocus, val, true);
      } else {
        setInputParameter({ ...inputParameter, value: val });
      }

    }
  }));

  const toDataMap = (list: ParamPair[]) => {
    const result: DataMap = {};

    list.forEach(pair => {
      result[pair.name] = pair.value;
    });

    return result;
  }

  useEffect(() => {
    const newParamList: ParamPair[] = [];

    if (typeof keyValuePairs === "object") {
      const itemsKeys = Object.keys(keyValuePairs);
      itemsKeys.map(keys => {
        newParamList.push({
          name: keys,
          value: keyValuePairs[keys]
        })
      });
    }

    setParamList(newParamList);

  }, [keyValuePairs]);

  useEffect(() => {
    let newOptionsList: Array<string> = [];

    if (typeof options === "string") {
      if (options.trim() !== "") {
        const items = JSON.parse(options);
        if (typeof items === "object") {
          newOptionsList.push("*")
          items.map(item => {
            newOptionsList.push(item.LeadDataValue.toLowerCase());
          });
        }
      };
      if (fieldNameDataType === "bit") {
        newOptionsList = ['true', 'false'];
      }

      setOptionsList(newOptionsList);
    }
  }, [options]);

  const renameParam = (paramIndex: number, newName: string, save: boolean = false) => {
    const newParamList: ParamPair[] = [...paramList];

    newParamList[paramIndex].name = newName;

    if (onChange && save) {
      onChange(toDataMap(newParamList));
    } else {
      setParamList(newParamList);
    }
  }

  const changeValue = (paramIndex: number, newValue: string, save: boolean = false) => {
    const newParamList: ParamPair[] = [...paramList];

    newParamList[paramIndex].value = newValue;

    if (onChange && save) {
      onChange(toDataMap(newParamList));
    } else {
      setParamList(newParamList);
    }
  }

  const deleteParam = (paramIndex: number) => {
    const newParamList: ParamPair[] = [...paramList];
    newParamList.splice(paramIndex, 1);

    if (onChange) {
      onChange(toDataMap(newParamList));
    }
  }

  const addParam = (key: string, value: string, save: boolean = true) => {
    const newParamList: ParamPair[] = [...paramList, { name: key, value }];

    if (onChange && save) {
      onChange(toDataMap(newParamList));
    } else {
      setParamList(newParamList);
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id={`mapper-name-input`}
              options={optionsList.filter(val => !paramList.map(list => { return list.name }).includes(val))}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push(`Add "${params.inputValue}"`);
                }

                return filtered;
              }}
              value={inputParameter.name}
              onFocus={evt => {
                setKeyFocus(null);
              }}
              onChange={(evt, value) => {
                const checkValue = value?.split("\"");
                let finalValue = value;
                if (checkValue && checkValue[1]) {
                  finalValue = checkValue[1];
                }
                setInputParameter({ ...inputParameter, name: finalValue });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={register}
                  name={`dataKey`}
                  label="Mapper Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              ref={inputRef}
              label="Mapper Value"
              value={inputParameter.value}
              variant="outlined"
              onFocus={evt => {
                setKeyFocus(null);
              }}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                setInputParameter({ ...inputParameter, value: evt.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.buttonGrid}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              startIcon={<NoteAddIcon />}
              onClick={() => {
                const keyName = inputParameter.name;
                const keyValue = inputParameter.value;

                if (keyName.trim() === "") {
                  openModal({
                    title: "Mapper Name",
                    content: (
                      <ul>
                        <li>This field is required.</li>
                      </ul>
                    ),
                    yPos: window && ((window.innerHeight * 0.5) - 200)
                  });
                } else {
                  addParam(keyName, keyValue);
                  setInputParameter({ name: '', value: '' });
                }
              }}
            >
              Add
                  </Button>
          </Grid>
        </Grid>
      </Grid>
      { (Object.keys(paramList).length !== 0 ? (
        paramList.map((param, index) => {
          const fieldId = `dataKey${index + 1}`;
          const autoFieldId = `mapper-name-input${index + 1}`;
          return (
            <Grid item xs={12} key={`key-mapper-grid-${index + 1}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <Autocomplete
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    key={`key-mapper-name-${index + 1}`}
                    id={autoFieldId}
                    options={optionsList.filter(val => !paramList.map(list => { return list.name }).includes(val))}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push(`Add "${params.inputValue}"`);
                      }

                      return filtered;
                    }}
                    value={param.name}
                    // getOptionSelected={param.name}
                    onFocus={evt => {
                      setKeyFocus(index);
                    }}
                    onChange={(evt, value) => {
                      const checkValue = value?.split("\"");
                      let finalValue = value;
                      if (checkValue && checkValue[1]) {
                        finalValue = checkValue[1];
                      }
                      renameParam(index, finalValue, true);
                      setKeyFocus(index);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputRef={register}
                        name={fieldId}
                        label="Mapper Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    key={`key-mapper-value-${index + 1}`}
                    name={`dataValue${index}`}
                    label="Mapper Value"
                    value={param.value || ""}
                    variant="outlined"
                    onFocus={evt => {
                      setKeyFocus(index);
                    }}
                    onChange={(evt) => {
                      changeValue(index, evt.target.value);
                    }}
                    onBlur={(evt) => {
                      changeValue(index, evt.target.value, true);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.buttonGrid}>
                  <Button
                    key={`key-mapper-btn-${index + 1}`}
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<DeleteForeverIcon />}
                    fullWidth
                    onClick={() => {
                      deleteParam(index);
                    }}
                  >
                    REMOVE
                </Button>
                </Grid>
              </Grid>
            </Grid>
          )
        })
      ) : (
        <Grid item xs={12}>
          <Alert severity="error" style={{ width: "100%" }}>
            <AlertTitle><strong>Error</strong></AlertTitle> Mapper cannot be empty!
          </Alert>
        </Grid>
      ))}
      <Modal />
    </>
  );
}

export const TransformMapperEditor = forwardRef(transformMapperEditor);