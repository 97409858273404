import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useModal, ContentLoading, StatusIcon } from "../../components";
import { GET_TRANSFORM_CODES } from "../../common/models/transformCode";
import { GetTransformCodes } from "../../common/models/types/GetTransformCodes";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from '@material-ui/icons/Edit';
import { AddCircle } from "@material-ui/icons";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { MappingModal } from "./mappingModal";

import LDPUIDataTable from "../../components/LDPUIDataTable";

interface TransformCodeProps {
  addTab?: Function;
  /* dispatch:Function; */
}

export default (props: TransformCodeProps) => {
  const [
    getTransformCodes,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<GetTransformCodes>(GET_TRANSFORM_CODES);

  const { Modal, closeModal, openModal, setContent } = useModal();

  const mapRowDataToColumns = (rowData: any, columns: any) => {
    if (rowData) {
      let newJson: any = {};
      columns.map((column: any, key: any) => {
        newJson[column.name] = rowData[key];
      });
      return newJson;
    } else return null;
  };

  const reload = () => {
    console.log("reload transform codes");
    refetch();
  }

  const columnsMUI = [
    {
      name: "TransformCodeId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <IconButton
              onClick={() => {
                openModal({
                  title: `Edit Mapping`,
                  icon: <EditIcon />,
                  iconColor: "orange",
                  content: (
                    <MappingModal
                      action={"edit"}
                      data={mapRowDataToColumns(tableMeta.rowData, columnsMUI)}
                      close={closeModal}
                      refetch={reload}
                    />
                  ),
                });
              }}
            >
              <LaunchIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "TransformCode1",
      label: "TransformCode1",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TransformCodeName",
      label: "TransformCodeName",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        display: false,
      },
    },
    // {
    //   name: "MethodName",
    //   label: "MethodName",
    //   options: {
    //     viewColumns: false,
    //     filter: false,
    //     sort: true,
    //     display: false,
    //   },
    // },
    {
      name: "BuyerId",
      label: "Buyer",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "VerticalName",
      label: "Vertical Name",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "SubVerticalName",
      label: "Sub Vertical Name",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (value === '' ? 'Global' : value);
        },
      },
    },
    {
      name: "FieldName",
      label: "Field Name",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "TargetFieldName",
      label: "Target Field Name",
      options: {
        viewColumns: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Map",
      label: "Map",
      options: {
        viewColumns: true,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (<div style={{ ...Style.CodeWrapper, width: "300px" }}>{value}</div>)
        },
      },
    },
    {
      name: "MethodName",
      label: "Method Name",
      options: {
        viewColumns: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "InputFieldType",
      label: "Input Field Type",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "OutputFieldType",
      label: "Output Field Type",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    getTransformCodes({
      variables: { where: 'Map != null' },
    });
  }, []);

  const options: any = {
    searchOpen: true,
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          startIcon={<AddCircle />}
          onClick={() => {
            openModal({
              title: "Add New Mapping",
              icon: <GroupAddIcon />,
              iconColor: "green",
              content: (
                <MappingModal
                  action={"create"}
                  data={null}
                  close={closeModal}
                  refetch={refetch}
                />
              ),
            });
          }}
        >
          Create New
        </Button>
      </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
            ldpTableId="transform-code-mapping-list"
            restoreFilters={true}
            title={"Transform Codes"}
            data={data?.LDPConfigQueryGroup?.TransformCode}
            columns={columnsMUI}
            options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
  CodeWrapper: {
    height: "120px",
    width: "300px",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
  CodeModal: {
    height: "60vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
};
