import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/react-hooks";
import { useModal, ContentLoading, StatusIcon } from "../../components";
import { GET_TRANSFORM_CODES } from "../../common/models/transformCode";
import { GetTransformCodes } from "../../common/models/types/GetTransformCodes";
import { Grid, Hidden, Paper } from "@material-ui/core";
import CodeIcon from '@material-ui/icons/Code';

import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone } from "../../common/utils/date";
import CustomMuiDatatableFilter, { genericFilter, userFilter, activeFilter, createDateFilter } from "../../components/customMuiDatatableFilter";

interface TransformCodeProps {
  addTab?: Function;
  /* dispatch:Function; */
}

export default (props: TransformCodeProps) => {
  const [
    getTransformCodes,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<GetTransformCodes>(GET_TRANSFORM_CODES);

  const { addTab } = props;

  const columnsMUI = [
    {
      name: "TransformCodeId",
      label: "ID",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "BuyerId",
      label: "Buyer",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "TransformCodeName",
      label: "Transform Code Name",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "TransformCode1",
      label: "Transform Code",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => (
          <Paper
            onClick={() => {
              openModal({
                title: "Request Body",
                icon: <CodeIcon />,
                iconColor: "green",
                content: (
                  <Paper style={Style.CodeModal}>
                    {value}
                  </Paper>
                ),
              });
            }}
            style={Style.CodeWrapper}>{value}</Paper>
        ),
        setCellProps: () => {
          return {
            style: {
              padding: "5px",
            },
          };
        },
      },
    },
    {
      name: "VerticalName",
      label: "Vertical Name",
      options: {
        viewColumns: false,
        sort: true,
        ...genericFilter
      },
    },
    {
      name: "SubVerticalName",
      label: "Sub Vertical Name",
      options: {
        viewColumns: false,
        sort: true,
        ...genericFilter
      },
    },
    {
      name: "MethodName",
      label: "Method Name",
      options: {
        viewColumns: false,
        sort: true,
        ...genericFilter
      },
    },
    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: false,
        sort: false,
        ...activeFilter
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {
        viewColumns: false,
        sort: false,
        ...userFilter
      },
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const dateValue = value || tableMeta.tableData[tableMeta.rowIndex].CreatedDate;
          return dateValue
            ? dateToPreferredTimezone(dateValue, "yyyy-MM-dd")
            : "";
        },
        ...createDateFilter()
      },
    },
  ];

  const { Modal, closeModal, openModal, setContent } = useModal();

  useEffect(() => {
    getTransformCodes({
      variables: { where: 'Map = null' },
    });
  }, []);

  const options: any = {
    searchOpen: true,
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Hidden only="sm">
        <Grid item xs md={6}>
          {" "}
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId="transform-code-list"
          restoreFilters={true}
          title={"Transform Codes"}
          data={data?.LDPConfigQueryGroup?.TransformCode}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
  CodeWrapper: {
    height: "120px",
    width: "300px",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
  CodeModal: {
    height: "60vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
};
