import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { Layout, PrivateRoute, ContentContainer, TabsContainer } from "../components";
import { RouteComponentProps } from "@reach/router";

import { TabProps } from "../components/tabs";
import { connect } from "react-redux";
import { RootState } from "../state";
import { updateTabContainers } from "../state/tabsReducer";
import TransformCodeIndex from "../views/transformCode";
import TransformCodeMapping from "../views/transformCode/mappingList";

interface Props {
  transformCodeTabs: any;
  dispatch: Function;
}

const TransformCode = ( { transformCodeTabs, dispatch }: Props) => {

  const TAB_CONTAINER = "tranform-code-tabs";

  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabsInitialized, setTabsInitialized] = useState(false);
  const [_tabs, setTabs] = useState<TabProps[]>([]);


  useEffect(() => {
    if (!transformCodeTabs.tabContainers[TAB_CONTAINER]) {
      //Initialize Redux tabsContainer for contracts
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Transform Code",
          tabId: "tranform-code-list",
          content: <TransformCodeIndex />,
          permalink: `/transformcode/`,
        },
        {
          title: "Mapping",
          tabId: "tranform-code-mapping-list",
          content: <TransformCodeMapping />,
          permalink: `/transformcode/`,
        },
      ];

      const initialContractsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialContractsTabContainer));
    } else {
      if (transformCodeTabs.tabContainers[TAB_CONTAINER]) {
        setTabs(transformCodeTabs.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(transformCodeTabs.tabContainers[TAB_CONTAINER].tabFocus);
        setTabsInitialized(true);
      }
    }
  }, [transformCodeTabs])

  const handleTabChangeFocus = (props:{tabs:TabProps[], focus: number, permalink: string, tabsContainerId: string}) => {
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
        tabFocus: props.focus,
        tabs: props.tabs,
      }})
    );
  };
  
  return (
    <ContentContainer>
      <TabsContainer
        tabs={_tabs}
        tabFocus={tabFocus}
        tabsContainerId={TAB_CONTAINER}
        onChange={handleTabChangeFocus}
      />
    </ContentContainer>
  );
};

const TransformCodeWrapper = connect((state: RootState) => ({
  transformCodeTabs: state.tabsSection,
}), null)(TransformCode);

export default (a: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={TransformCodeWrapper} path="/transformcode/" pagetitle="Transform Code"/>
      </Router>
    </Layout>
  );
};